import React, { useState } from "react";
import "../../App.css";
import { Link } from "react-router-dom";
import axios from "axios";
import Popup from "../Home/Popup";

const Footer = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [showPopup, setShowPopup] = useState(false);

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const [subscribed, setSubscribed] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const supportRequest = "subcribed";
      const subject = "Subcription Confirmed";
      const message = "Subscribed User";
      const data = { ...formData, subject, supportRequest, message };
      // Send email to backend for subscription
      console.log(data);
      await axios.post(
        "https://api.daqi.in/api/website/sendSubscribeMail",
        data
      );
      await axios.post(
        "https://api.daqi.in/api/website/InsertSubscribeData",
        data
      );
      setSubscribed(true);
      setError("");
      setFormData({
        email: "",
      });
    } catch (error) {
      setError("Failed to subscribe. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
         <Popup
        isOpen={showPopup}
        setIsOpen={setShowPopup}
        onRequestClose={handleClosePopup}
        imageUrl="assets/img/banner_image_popup.png" 
      />
      <footer className="text-white" style={{ backgroundColor: "#2d2d2d" }}>
        <div className="container">
          <div className="row footer-row">
            <div className="col-md-6 col-lg-3 mb-4 mb-lg-0 responsiveFooterCenter">
              <img
                src="assets/img/daqifooterlogo.png"
                alt="Daqi Logo"
                style={{ height: "auto", width: "225px" }}
              />
              <p
                style={{
                  marginLeft: "16px",
                  paddingTop: "8px",
                  textAlign: "left",
                  fontWeight: "initial",
                  fontSize: "15px",
                }}
              >
                Buy FMEG and building construction materials on credit with Daqi
                platform
              </p>
            </div>
            <div className="col-md-6 col-lg-3 px-10 quickLinkFooter">
              <h5 className="linkHeadTag">Quick Links</h5>
              <ul className="list-unstyled">
                <li>
                  <Link to="/privacy-policy" className="footer-link">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions" className="footer-link">
                    Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link to="/grievance-redressal" className="footer-link">
                    Grievance-redressal
                  </Link>
                </li>
                <li>
                  <Link to="/cancellation-and-refunds" className="footer-link">
                    Cancellation & Refunds
                  </Link>
                </li>
                <li>
                  <Link to="/FAQ's" className="footer-link">
                    FAQ's
                  </Link>
                </li>
                <li>
                  <Link to="/AboutUs" className="footer-link">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/shipping-policy" className="footer-link">
                    Shipping Policy
                  </Link>
                </li>
                {/* <li>
                  <Link to="/lending-privacy-policy" className="footer-link">
                    Lending Privacy Policy
                  </Link>
                </li> */}
              </ul>
            </div>
            <div className="col-md-6 col-lg-3 mb-4 mt-0 quickAddressFooter">
            <h5 className="linkHeadTag">Contact Us</h5>
              <div className="footerNameAdd">
                <div className="visitMngProp fulladdress">
                  <div className="visitingmap">
                    <img src="assets/img/icon/location-sharp.svg" alt="icon" />
                  </div>
                  <div className="vistIndia">
                    <h6 className="footer-h6">Come Visit Us</h6>
                    {/* <span>
                      1st Floor, Raghuleela, Arcade Vishwaroop, Infotech Pvt.
                      Ltd, Mumbai, Maharashtra 400703
                    </span> */}
                    <p>
                      {" "}
                      2nd Floor, Raghuleela, Arcade Vishwaroop, Infotech Pvt.
                      Ltd, Mumbai, Maharashtra 400703
                    </p>
                  </div>
                </div>
              </div>

              <div className="footerNameAdd">
                <div className="visitMngProp">
                  <img src="assets/img/icon/telephone.svg" alt="icon" />
                  <div className="vistIndia">
                    <h6 className="footer-h6">Phone Number</h6>
                    <p>India +91 8109743037</p>
                  </div>
                </div>
              </div>

              <div className="footerNameAdd">
                <div className="visitMngProp">
                  <img src="assets/img/icon/email.svg" alt="icon" />
                  <div className="vistIndia">
                    <h6 className="footer-h6">Send Us a Message</h6>
                    <p>support@daqi.in</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3"
              style={{ width: "20% !important" }}
            >
              <div className="subscribedMail">
                <h6>Subscribe</h6>
                <p className="newLetter">
                  Subscribe to our newsletter and get the first consulting for
                  free.
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="changeNamechlg">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Enter email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <button type="submit" className="btn">
                      <img src="assets/img/icon/email.svg" alt="email" />
                    </button>
                  </div>
                </form>
                {loading && <p>Loading...</p>}
                {subscribed && <p>Thank you for subscribing!</p>}
                {error && <p>{error}</p>}
                <p className="getLatest">Get latest updates and offers.</p>
                <ul className="orderMailCheck">
                  <li>
                    <a href="#">
                      <img src="assets/img/icon/twitter.svg" alt="shareIcon" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src="assets/img/icon/instagram.svg"
                        alt="shareIcon"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="assets/img/icon/facebook.svg" alt="shareIcon" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="assets/img/icon/linkdin.svg" alt="shareIcon" />
                    </a>
                  </li>
                  <li>
                    <a href="https://daqi-cdn-bucket.s3.ap-south-1.amazonaws.com/daqiSalesProdApk/SalesProd1.0.4.apk">
                      <img style={{maxWidth:25}} src="assets/img/icon/download.png" alt="shareIcon" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div
        className="bg-gradient-to-t from-primary to-secondary"
        style={{
          backgroundImage: "linear-gradient(to right, #fc5553, #f57646)",
        }}
      >
        <p className="footerCopyrightsec">
          Copyright © 2024 Ptolemy Technology Private Ltd.
        </p>
      </div>
    </>
  );
};

export default Footer;
